import router from "@/router"

export default {
    state: {
        buyDetail: '',
        buyConfirm: ''
    },
    mutations: {
        setBuyDetail(state, payload) {
            state.buyDetail = payload.parameters
        },
        setBuyConfirm(state, payload) {
            state.buyConfirm = payload.confirm
        },
    },
    actions: {
        async getBuyDetail({dispatch, commit}, currency) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/buy/parameters/' + currency})
            .then(response => response.json())
            .then(data => {
                commit('setBuyDetail', data)
            })
        },
        async createBuy({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/buy/create', data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    commit('setBuyConfirm', data)
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async confirmBuy({dispatch, commit}, guid) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/buy/confirm/' + guid})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    dispatch('getCurrencies')
                    router.push('/dashboard/history')
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
    },
    getters: {
        getBuyDetail(state) {
            return state.buyDetail
        },
        getBuyConfirm(state) {
            return state.buyConfirm
        }
    }
}