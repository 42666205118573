import router from "@/router"

export default {
    state: {
        sellDetail: '',
        sellConfirm: ''
    },
    mutations: {
        setSellDetail(state, payload) {
            state.sellDetail = payload.parameters
        },
        setSellConfirm(state, payload) {
            state.sellConfirm = payload.confirm
        },
    },
    actions: {
        async getSellDetail({dispatch, commit}, currency) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/sell/parameters/' + currency})
            .then(response => response.json())
            .then(data => {
                commit('setSellDetail', data)
            })
        },
        async createSell({dispatch, commit}, data) {
            dispatch('sendRequest', {method: 'POST', endpoint: '/sell/create', data})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    commit('setSellConfirm', data)
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
        async confirmSell({dispatch, commit}, guid) {
            dispatch('sendRequest', {method: 'GET', endpoint: '/sell/confirm/' + guid})
            .then(response => response.json())
            .then(data => {
                if(data.response === true) {
                    dispatch('getCurrencies')
                    router.push('/dashboard/history')
                } else {
                    commit('notification', {message: data.messages})
                }
            })
        },
    },
    getters: {
        getSellDetail(state) {
            return state.sellDetail
        },
        getSellConfirm(state) {
            return state.sellConfirm
        }
    }
}