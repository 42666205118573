<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title justify-content-between">
            <span>Вывод средств</span>
            <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
        </h1>
    </div>
    <div class="tab-arrow">
        <div class="tab horizontal account default-color">
            <router-link to="/dashboard/withdraw" class="tab-button">Криптовалюты</router-link>
            <router-link to="/dashboard/payment-services/259" class="tab-button">Карты Банков</router-link>
            <router-link to="/dashboard/withdraw/fiat" class="tab-button">Фиат (кэш)</router-link>
            <router-link to="/dashboard/withdraw/bank" class="tab-button">Банк</router-link>
        </div>
    </div>
    <div class="dashboard-wrap">
        <div class="dashboard-list">
            <div class="row" v-if="category == '' || category == undefined">
                <template v-for="item in list" :key="item">
                    <div class="col-6 col-md-4 col-lg-3 col-xl-3" v-if="item.can_withdraw === true && search(item)">
                        <router-link :to="'/dashboard/withdraw/detail/' + item.currency" class="list-item darks">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>{{ item.currency }}</b>
                                    <span>{{ item.balance }}</span>
                                </div>
                                <small class="list-item-button d-none"></small>
                            </div>
                        </router-link>
                    </div>
                </template>
            </div>
            <div class="row" v-if="category == 'fiat'">
                <template v-for="item in cashList" :key="item">
                    <div class="col-6 col-md-6 col-lg-6 col-xl-4" v-if="item.id == '57'" v-on:click="getMethodDetail(item)">
                        <div class="list-item">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>USD/{{ item.currency }}</b>
                                    <span class="active">{{ item.name }}</span>
                                </div>
                                <small class="list-item-button d-none"></small>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row" v-if="category == 'bank'">
                <template v-for="item in cashList" :key="item">
                    <div class="col-6 col-md-6 col-lg-6 col-xl-4" v-if="item.id == '58'" v-on:click="getMethodDetail(item)">
                        <div class="list-item">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>USD/{{ item.currency }}</b>
                                    <span class="active">{{ item.name }}</span>
                                </div>
                                <small class="list-item-button d-none"></small>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-content">
            <h1 class="modal-title text-center mb-4">{{ detail.name }}</h1>
            <p class="modal-text text-center" v-html="detail.info.description"></p>
            <button class="button block lg" v-on:click="closeModal">Закрыть</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalStatus: false,
            searchValue: '',
            category: '',
            detail: ''
        }
    },
    mounted() {
        this.category = this.$router.currentRoute.value.params.category
    },
    computed: {
        list() {
            return this.$store.getters.getCurrencies
        },
        cashList() {
            return (this.$store.getters.getWithdrawCashList)
                ? this.$store.getters.getWithdrawCashList
                : this.$store.dispatch('getWithdrawCashList')
        }
    },
    watch: {
        $route() {
            this.category = this.$router.currentRoute.value.params.category
        }
    },
    methods: {
        showModal() {
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
            if(this.category === 'fiat') {
                this.amount = ''
                this.$store.dispatch('clearFiatMethod')
            }
        },
        search(item) {
            return (item.currency.search(this.searchValue) !== -1 || item.currency.toLowerCase().search(this.searchValue) !== -1) ? true : false
        },
        getMethodDetail(item) {
            this.detail = item
            this.showModal()
        }
    }
}
</script>